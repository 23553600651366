function validate_form(rules) {
	validate_removeErrors();
	var e = 0;
	for (var rule in rules[0]) {
		if (rules[0].hasOwnProperty(rule)) {
			e += input_validate(rule, rules[0][rule], rules[1][rule], rules[2][rule], rules[3], rules);
		}
	}
	if (e == 0) {
		return true;
	} else {
		$('.validation_error').closest('.alert').removeClass('hidden');

	}
}
function validate_setErrors(errors) {
	//validate_removeErrors();
	var e = 0;
	for (var error in errors) {
		if (errors.hasOwnProperty(error)) {
			set_error(error, errors[error][0]);
			//console.log(error);
		}
	}
	if (e > 0)
		$(".validation_error").append('</ul>');

	if (e > 0) return true;
}

//
function validate_unhandled(errors) {
	//validate_removeErrors();
	var e = 0;
	for (var error in errors) {
		if (errors.hasOwnProperty(error)) {
			if (validate_isUnhandled(error)) {
				if (e == 0)
					$(".validation_error").append('<ul>');
				$.each(errors[error], function(k, m) {
					$(".validation_error").append('<li>' + m + '</li>');
				});

				e++;
			}
			//console.log(error);
		}
	}
	if (e > 0)
		$(".validation_error").append('</ul>');

	if (e > 0) {
		$('.validation_error').closest('.alert').removeClass('hidden');
		return true;
	}
}
function splitRule(rule, allRules) {

}
function input_validate(name, rule, message, attribute, messages, allrules) {

	//iterate through messages to see if any keys match rule "required|max"
	var rules = rule.split("|"); //split rules
	var errmsg = "";
	//iterate through rules
	for (var r in rules) {
		var rr = rules[r].split(":");
		var condition = "";
		if (messages[rr[0]] != undefined) {
			errmsg = messages[rr[0]];
			if (rr[1] != undefined) condition = rr[1];

			//check if errmsg is an array and then assign a proper errormsg
			var type = "string";
			if (typeof (errmsg) != "string") {
				if ($('[name="' + name + '"]').is('file')) {
					type = 'file';
				} else {
					if (rules.indexOf('array') != -1) {
						type = 'array';
					}
					if (rules.indexOf('numeric') != -1) {
						type = 'numeric';
					}
					if (rules.indexOf('integer') != -1) {
						type = 'numeric';
					}
					if (rules.indexOf('string') != -1) {
						type = 'string';
					}
					if (rules.indexOf('digits') != -1) {
						type = 'string';
					}
				}
				errmsg = errmsg[type]; //set default value
			}


			if (validate_key(name, rr[0], type, condition, attribute, errmsg, allrules)) {
				return 1;
			}
		}
	}
	input_removeError(name);
	return 0;
}

function validate_key(name, key, type, condition, attribute, errmsg, allrules) {

	if (name + "." + key in allrules[1]) {
		errmsg = allrules[1][name + "." + key];
	}
	errmsg = errmsg.replace(':attribute', attribute);
	errmsg = errmsg.replace(':other', allrules[2][condition]);
	if (errmsg.indexOf(':max') != -1 && errmsg.indexOf(':max') != -1) {
		//min and max both present so split the condition string
		if (condition.indexOf(',')) {
			errmsg = errmsg.replace(':min', condition.substr(0, condition.indexOf(',')));
			errmsg = errmsg.replace(':max', condition.substr(condition.indexOf(',') + 1));
		}
	} else {
		errmsg = errmsg.replace(':max', condition);
		errmsg = errmsg.replace(':min', condition);
	}
	errmsg = errmsg.replace(':digits', condition);

	if ($('[name="' + name + '"]').is('input')) {
		if (validate_isfail(name, $('[name="' + name + '"]').val(), key, type, condition)) {
			//$('[name="'+name+'"]').on('focus',function(){input_removeError($(this).prop('name'));});	
			set_error(name, errmsg);
			return 1;
		}
	}
	if ($('[name="' + name + '"]').is('select')) {
		if (validate_isfail(name, $('[name="' + name + '"]').find(":selected").val(), key, type, condition)) {
			//$('[name="'+name+'"]').on('focus',function(){input_removeError($(this).prop('name'));});	
			set_error(name, errmsg);
			return 1;
		}
	}
	return 0;
}

function validate_isUnhandled(name) {
	var unhandled = true;
	if ($('[name="' + name + '"]').parent().find(".help-block").length) {
		//error message holder found in the immediate neighborhood	
		unhandled = false;
	} else
		if ($('[name="' + name + '"]').closest('.form-group').find(".help-block").length) {
			//go to a level of formgroup this means that it is shared
			unhandled = false;
		}
	return unhandled;
}

function set_error(name, errmsg) {
	if ($('[name="' + name + '"]').parent().find(".help-block").length) {
		//error message holder found in the immediate neighborhood	
		$('[name="' + name + '"]').parent().addClass('has-error');
		$('[name="' + name + '"]').parent().find(".help-block").html(errmsg);
	} else
		if ($('[name="' + name + '"]').closest('.form-group').find(".help-block").length) {
			//go to a level of formgroup this means that it is shared
			$('[name="' + name + '"]').closest('.form-group').addClass('has-error');
			if ($('[name="' + name + '"]').closest('.form-group').find(".help-block").html() != "")
				$('[name="' + name + '"]').closest('.form-group').find(".help-block").append('<br>' + errmsg);
			else
				$('[name="' + name + '"]').closest('.form-group').find(".help-block").html(errmsg);
		} else
			if ($(".help-block[for='" + name + "']").length) {
				$('[name="' + name + '"]').parent().addClass('has-error');
				$(".help-block[for='" + name + "']").parent().addClass('has-error');
				$(".help-block[for='" + name + "']").html(errmsg);
			}
}
function isInt(value) {
	var x = parseFloat(value);
	return !isNaN(value) && (x | 0) === x;
}
//return true if fail validation
function validate_isfail(name, value, key, type, condition) {
	var o_val = value;
	if (typeof (value) == 'undefined') value = "";
	switch (key) {
		case 'unique':
			return false;
			break;
		case 'required':
			return value == "";
			break;
		case 'digits':
			var re = new RegExp('^[0-9]{' + condition + '}$');
			return re.test(value) == false;
			break;
		case 'digits_between':
			var re = new RegExp('^[0-9]{' + condition + '}$');
			return re.test(value) == false;
			break;
		case 'in':
			condition = condition.replace(/,/g, '|');
			var re = new RegExp('^' + condition + '*$');
			return re.test(value) == false;
			break;
		case 'integer':
			return !isInt(value);
		case 'string':
			return false;
		case 'max':
			switch (type) {
				case "array":
					return false;
					break;
				case "numeric":
					return value > condition;
					break;
				case "file":
					return $('[name="' + name + '"]').files[0].size > condition;
					break;
				case "string":
				default:
					return value.length > condition;
			}
			break;
		case 'min':
			switch (type) {
				case "array":
					return false;
					break;
				case "numeric":
					return value < condition;
					break;
				case "file":
					return $('[name="' + name + '"]').files[0].size < condition;
					break;
				case "string":
				default:
					return value.length < condition;
			}
			break;
		case 'email':
			var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(value) == false;
			break;
		case 'regex':
			condition = condition.replace(/^\/|\/$/g, '');
			var re = new RegExp(condition);
			return re.test(value) == false;
			break;
		case 'same':
			return $('[name="' + condition + '"]').val() != value;

			break;
		default:
			return false;

	}
	return false;
}
function validate_removeErrors() {
	$('.has-error').removeClass('has-error');
	$('.help-block').html('');
	$('.validation_error').html('');
	$('.alert').alert('close');
}
function input_removeError(name) {
	$('[name="' + name + '"]').parent().removeClass('has-error');
	$('[name="' + name + '"]').parent().find(".help-block").html('');
}
